import '@adornis/buildify/client/components/x-buildify-permission-selection.js';
import { ExtensionBoilerplate } from '@adornis/buildify/client/extensions/ContentExtensionBoilerplate';
import type { BuildifyExtension } from '@adornis/buildify/client/globals/types.js';
import { CustomContent } from '@adornis/buildify/db/CustomContent';
import { BaseCustomContent } from '@adornis/buildify/db/extensions/BaseCustomContent';
import { RXController } from '@adornis/chemistry/controllers/RXController';
import { xComponents } from '@adornis/chemistry/elements/x-components';
import { html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { filter, switchMap } from 'rxjs';
import './../d-buildify';

export const BuildifyCampusCustomContentExtension: BuildifyExtension<BaseCustomContent> = {
  class: BaseCustomContent,
  render: (instance, { defaultValues, isSubelement, dropzoneBefore, dropzoneAfter }) => {
    return html`
      <custom-content-extension
        .content=${instance}
        .defaultValues=${defaultValues}
        ?sub=${isSubelement}
        .hasDropzoneBefore=${dropzoneBefore}
        .hasDropzoneAfter=${dropzoneAfter}
      ></custom-content-extension>
    `;
  },
  editor: () => {
    return html` <d-text> Nicht editierbar </d-text> `;
  },
};

@customElement('custom-content-extension')
export class XTextExtension extends ExtensionBoilerplate<BaseCustomContent> {
  private _customContent = new RXController(
    this,
    this.content.observable.pipe(
      filter(Boolean),
      switchMap(content => CustomContent.getByID<CustomContent>(content.referenceID)(CustomContent.allFields)),
    ),
  );

  protected override _content(content: BaseCustomContent) {
    return xComponents(html`
      <d-buildify .extensions=${this._consumedExtensions.value} .value=${this._customContent.value?.content}>
      </d-buildify>
    `);
  }
}
