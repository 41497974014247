import type { Maybe } from '@adornis/base/utilTypes.js';
import { Int } from '@adornis/baseql/baseqlTypes.js';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators.js';
import { getCollection, getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { selectionSet, type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { Page } from '@adornis/buildify/db/Page.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { Versioned } from '@adornis/versioned-entity/db/versioned-mongo-entity.js';
import { DateTime } from 'luxon';
import type { UpdateResult } from 'mongodb';
import { PathAlreadyTakenError } from './errors/PathAlreadyTakenError.js';

@Versioned({ versionCollection: 'page-versions' })
@Entity()
export class DigitaleHeldenPage extends Page {
  static override _class = 'DigitaleHeldenPage';
  static override _collectionName = 'pages';

  static override get allFields() {
    return selectionSet(() => this, 10);
  }

  @validate(nonOptional({ allowFalsy: true }))
  @Field(type => Int, { default: v => v ?? 0 })
  version!: number;

  @validate(nonOptional())
  @Field(type => String)
  override name!: string;

  @validate(nonOptional())
  @Field(type => String)
  override path!: string;

  @Field(type => Int)
  orderIndex?: Maybe<number>;

  @Field(type => String)
  parentPageID: Maybe<string>;

  @Field(type => DateTime)
  updatedAt: Maybe<DateTime>;

  static override async transformBeforeUpdate(
    oldEntity: {
      _id?: string | undefined;
      _class?: string | undefined;
    },
    newEntity: DigitaleHeldenPage,
  ) {
    await super.transformBeforeUpdate(oldEntity, newEntity);
    newEntity.version = newEntity.version + 1;
  }

  static override async afterUpdate(
    oldEntity: {
      _id?: string | undefined;
      _class?: string | undefined;
    },
    newEntity: DigitaleHeldenPage,
    result: UpdateResult,
  ) {
    await super.afterUpdate(oldEntity, newEntity, result);
  }

  @Mutation(type => String)
  static checkIfPathAlreadyTaken(@Arg('instance', type => DigitaleHeldenPage) instance: DigitaleHeldenPage) {
    return async (): Promise<void> => {
      if (instance.parentPageID) {
        // hier wirds komplizierter
        const parent = await this.getRootPageOfSubpage(instance.parentPageID)(DigitaleHeldenPage.allFields);
        const children = await this.getChildPages(parent._id)({ _id: 1, path: 1 });
        for (const child of children) {
          if (child.path.toLowerCase() === instance.path.toLowerCase()) throw new PathAlreadyTakenError();
        }
      } else {
        console.log('aber wir landen schon hier oder???');
        const pageCollection = await getCollection(DigitaleHeldenPage._class);
        const results = await pageCollection.find<DigitaleHeldenPage>({ parentPageID: null }).toArray();
        for (const result of results) {
          if (result.path.toLowerCase() === instance.path.toLowerCase()) throw new PathAlreadyTakenError();
        }
      }
    };
  }

  @Query(type => DigitaleHeldenPage)
  static getRootPageOfSubpage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DigitaleHeldenPage>): Promise<DigitaleHeldenPage> => {
      const collection = await getCollection(DigitaleHeldenPage._class);

      const page = await collection.findOne<DigitaleHeldenPage>({ _id: pageID });
      if (!page) throw new Error(`404: page for id '${pageID}' not found.`);
      // if no parentID is given, then we know that this page is on root level
      if (!page.parentPageID) return page;
      // if we are not on root level, go recursivly deeper
      return this.getRootPageOfSubpage(page.parentPageID)(gqlFields);
    };
  }

  @Query(type => [DigitaleHeldenPage])
  static getChildPages(@Arg('parentPageID', type => String) parentPageID: string) {
    return async (gqlFields: BaseQLSelectionSet<DigitaleHeldenPage>): Promise<DigitaleHeldenPage[]> => {
      if (context.serverContext) return [];
      const collection = await getRawCollection<DigitaleHeldenPage>(DigitaleHeldenPage._collectionName);
      const parentPage = await collection.findOne<DigitaleHeldenPage>({ _id: parentPageID });
      if (!parentPage) throw new Error('product page not found');

      const result = await collection
        .aggregate<DigitaleHeldenPage>([
          {
            $graphLookup: {
              from: DigitaleHeldenPage._collectionName,
              startWith: '$_id',
              connectFromField: '_id',
              connectToField: 'parentPageID',
              as: 'children',
            },
          },
          {
            $match: {
              _id: parentPageID,
            },
          },
          {
            $unwind: '$children',
          },
          {
            $replaceRoot: {
              newRoot: '$children',
            },
          },
        ])
        .toArray();

      return result;
    };
  }
}
