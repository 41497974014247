import type { Maybe } from '@adornis/base/utilTypes.js';
import { Arg, Entity, Field, Mutation, Query } from '@adornis/baseql/decorators.js';
import { getCollection, getRawCollection } from '@adornis/baseql/server/collections.js';
import { context } from '@adornis/baseql/server/context.js';
import { type BaseQLSelectionSet } from '@adornis/baseql/utils/queryGeneration.js';
import { CustomContent } from '@adornis/buildify/db/CustomContent.js';
import { DigitaleHeldenPage } from '@adornis/digitale-helden-shared/db/DigitaleHeldenPage.js';
import { LASUser } from '@adornis/digitale-helden-shared/db/las-user.js';
import { Permissions } from '@adornis/digitale-helden-shared/db/permissions.js';
import { CurrentUserInfo } from '@adornis/users/db/currentUserInfo.js';
import { AdornisRoleToContextsWrapper } from '@adornis/users/db/roleToContextsWrapper.js';
import { validate } from '@adornis/validation/decorators.js';
import { nonOptional } from '@adornis/validation/functions/nonOptional.js';
import { CampusRoute, PathToCampusRoute } from '../../_routing/db/enums.js';
import { checkPermission, checkPermissionOrProductOwning } from '../../db/helpers.js';
import { DubniumPagePublished } from './DubniumPagePublished.js';
import type { CampusPageType } from './enums.js';

@Entity()
export class CampusPage extends DigitaleHeldenPage {
  static override _class = 'CampusPage';

  @validate(nonOptional())
  @Field(type => String)
  shortName!: string;

  @validate(nonOptional())
  @Field(type => [String], { default: v => v ?? [] })
  productIDs!: string[];

  @Field(type => String) documentType: Maybe<CampusPageType>;

  protected static override async checkUniquePath(entity: CampusPage) {
    if (!entity.path) throw new Error('Jede Seite braucht einen eindeutigen Pfad über den sie erreichbar ist');
    // check existing paths / paths need to be unique
    const existingPaths = await (
      await getRawCollection(this._collectionName)
    ).countDocuments({ path: this.generatePathFindRegex(entity.path), parentPageID: entity.parentPageID });
    if (existingPaths)
      throw new Error('Neue Seite konnte nicht erstellt werden, da eine Seite mit dem selben Pfad bereits existiert');
  }

  @Mutation(type => String)
  static saveRawCustomContent(@Arg('instance', type => CustomContent) instance: CustomContent) {
    return async (): Promise<string> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection<CustomContent>(CustomContent._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Query(type => String)
  static saveRawPage(@Arg('instance', type => CampusPage) instance: CampusPage) {
    return async (): Promise<string> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getRawCollection<CampusPage>(CampusPage._collectionName);
      const result = await collection.replaceOne({ _id: instance._id }, instance.toJSON());
      return result.upsertedId;
    };
  }

  @Mutation(type => String)
  static removePage(@Arg('pageID', type => String) pageID: string) {
    return async (): Promise<void> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getCollection<CampusPage>(CampusPage._class);
      await collection.deleteOne({ _id: pageID });

      const children = await this.getDirectChildrenOfPage(pageID)({ _id: 1 });
      for (const child of children) {
        await this.removePage(child._id)();
      }
    };
  }

  @Query(type => [CampusPage])
  static getAllPages() {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<CampusPage[]> => {
      await checkPermission({ context, permission: 'Designer.View' });

      const collection = await getCollection(CampusPage._class);
      const result = await collection.find<CampusPage>({}).toArray();
      return result;
    };
  }

  @Query(type => [CampusPage])
  static getDirectChildrenOfPage(@Arg('pageID', type => String) pageID: string) {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<CampusPage[]> => {
      await checkPermission({ context, permission: 'Designer.View' });

      const collection = await getCollection(CampusPage._class);
      const result = await collection.find<CampusPage>({ parentPageID: pageID }).toArray();
      return result;
    };
  }

  @Query(type => CampusPage)
  static deletePageByID(@Arg('id', type => String) id: string) {
    return async (): Promise<void> => {
      await checkPermission({ context, permission: 'Designer.Edit' });

      const collection = await getCollection(CampusPage._class);
      await collection.deleteOne({ _id: id });
    };
  }

  @Query(type => CampusPage)
  static getActivePageByPath(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<CampusPage | null> => {
      const collection = await getCollection(CampusPage._class);
      const result = await collection.findOne<CampusPage>({ path });
      if (!result) return null;
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        email: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      if (user?.hasRole(Permissions.UserRoles.DESIGNER) || user?.hasRole(Permissions.UserRoles.SUPER_ADMIN))
        return result;

      const publishment = await DubniumPagePublished.getPublishmentByPage(result._id)({ _id: 1 });
      if (!publishment) return null;
      return result;
    };
  }

  @Query(type => String)
  static getPathToProduct(@Arg('productID', type => String) productID: string) {
    return async (): Promise<string> => {
      const collection = await getCollection(CampusPage._class);
      const page = await collection.findOne<CampusPage>({ productIDs: productID });
      if (!page) throw new Error('Dieses Produkt hat noch keine Content-Page definiert');
      const publishment = await DubniumPagePublished.getPublishmentByPage(page._id)({ _id: 1 });
      if (!publishment) throw new Error('page not published yet');
      return `${PathToCampusRoute(CampusRoute.CONTENT)}${page.path}`;
    };
  }

  @Query(type => [CampusPage])
  static getPagesByProductPage(@Arg('productPageID', type => String) productPageID: string) {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<CampusPage[]> => {
      if (context.serverContext) return [];
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      const collection = await getRawCollection<CampusPage>(CampusPage._collectionName);
      const productPage = await collection.findOne<CampusPage>({ _id: productPageID });

      if (!productPage || !productPage.productIDs || productPage.productIDs.length === 0)
        throw new Error('product page not found');

      let hasPermission = false;
      for (const productID of productPage.productIDs) {
        try {
          await checkPermissionOrProductOwning({
            context,
            permission: Permissions.BuildifyForceViewPermission,
            productID,
          });
          hasPermission = true;
        } catch {}
      }

      if (!hasPermission) throw new Error('no permission for this page');

      const hasForcedPermission = user && user.hasPermission(Permissions.BuildifyForceViewPermission);

      const result = await collection
        .aggregate<CampusPage>([
          {
            $graphLookup: {
              from: CampusPage._collectionName,
              startWith: '$_id',
              connectFromField: '_id',
              connectToField: 'parentPageID',
              as: 'children',
            },
          },
          {
            $match: {
              _id: productPageID,
            },
          },
          {
            $unwind: '$children',
          },
          // ==================================== START preview check ================================
          // wenn preview, dann muss nicht geprüft werden ob der Content bereits veröffentlicht wurde
          ...(hasForcedPermission
            ? []
            : [
                {
                  $lookup: {
                    from: DubniumPagePublished._collectionName,
                    foreignField: 'pageID',
                    localField: 'children._id',
                    as: 'children.publishment',
                  },
                },
                {
                  $unwind: '$children.publishment',
                },
                {
                  $match: {
                    'children.publishment': { $ne: null },
                  },
                },
              ]),
          // ------------------------------------- ENDE preview check --------------------------------
          {
            $replaceRoot: {
              newRoot: '$children',
            },
          },
        ])
        .toArray();

      return result;
    };
  }

  @Query(type => CampusPage)
  static getProductPageByURL(@Arg('path', type => String) path: string) {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<CampusPage> => {
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      if (!user) throw new Error("no user found in context by requesting 'getProductPageByURL'");

      // Die Preview zeigt jeglichen Content, auch den unveröffentlichten.
      // Deshalb ist wichtig, dass nur Super-Admins darauf zugreifen können.
      const hasForcedPermission = user.hasPermission(Permissions.BuildifyForceViewPermission);

      const collection = await getCollection(CampusPage._class);
      const page = await collection.findOne<CampusPage>({
        path,
        productIDs: {
          $exists: true,
          $ne: [],
        },
      });

      if (!page) throw new Error('no page found');

      // wenn es sich um die Preview handelt, sollen auch unveröffentlichte Seiten gezeigt werden.
      // Deshalb wird sie hier schon zurückgegeben
      if (hasForcedPermission) return page;

      // prüfen, ob die Seite bereits veröffentlicht wurde.
      // Wenn nicht -> Error
      const publishment = await DubniumPagePublished.getPublishmentByPage(page._id)({ _id: 1 });
      if (!publishment) throw new Error('page not published yet');
      if (!page.productIDs) throw new Error('how did you get here');

      if (
        user.roles.findIndex(
          role =>
            role.name === Permissions.UserRoles.PARTICIPANT && page.productIDs?.some(id => role.contexts.includes(id)),
        ) === -1 &&
        user.roles.findIndex(role => role.name === Permissions.UserRoles.SUPER_ADMIN) === -1
      ) {
        throw new Error('no permission for this product page');
      }

      return page;
    };
  }

  @Query(type => CampusPage)
  static getPageByProductPage(
    @Arg('productPageID', type => String) productPageID: string,
    @Arg('path', type => String) path: string,
  ) {
    return async (gqlFields: BaseQLSelectionSet<CampusPage>): Promise<Maybe<CampusPage>> => {
      if (context.serverContext) return null;
      const user = await CurrentUserInfo.getMyself<LASUser>()({
        _id: 1,
        roles: AdornisRoleToContextsWrapper.allFields,
      });
      const hasForcedPermission = user && user.hasPermission(Permissions.BuildifyForceViewPermission);
      const collection = await getRawCollection(CampusPage._collectionName);
      const result = await collection
        .aggregate<CampusPage>([
          {
            $graphLookup: {
              from: CampusPage._collectionName,
              startWith: '$_id',
              connectFromField: '_id',
              connectToField: 'parentPageID',
              as: 'children',
            },
          },
          {
            $match: {
              _id: productPageID,
            },
          },
          {
            $unwind: '$children',
          },
          // ==================================== START preview check ================================
          // wenn preview, dann muss nicht geprüft werden ob der Content bereits veröffentlicht wurde
          ...(hasForcedPermission
            ? [
                {
                  $match: { 'children.path': path },
                },
              ]
            : [
                {
                  $lookup: {
                    from: DubniumPagePublished._collectionName,
                    foreignField: 'pageID',
                    localField: 'children._id',
                    as: 'children.publishment',
                  },
                },
                {
                  $unwind: '$children.publishment',
                },
                {
                  $match: {
                    'children.path': path,
                    'children.publishment': { $ne: null },
                  },
                },
              ]),
          // ------------------------------------- ENDE preview check --------------------------------
          {
            $replaceRoot: {
              newRoot: '$children',
            },
          },
        ])
        .toArray();

      if (result.length > 1)
        throw new Error(`multiple possible matches found for productID ${productPageID} and subpage path ${path}`);

      return result[0];
    };
  }
}
